import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"

import PrimaryButton from "../components/primary-button"

const ShortlistPage = () => (
  <Background>
    <Layout>
      <Seo title="Winners" />
      <Container>
        <PageTitle text="Winners" />

        <p className="text-center m-5" style={{textTransform:`uppercase`, color: `white`, fontWeight:`bold`}}>To be announced on 25th November 2021 at Imperial War Museum North</p>

        <div className="text-center" style={{ marginBottom: `6rem`}}>
          <PrimaryButton to="/awards/" text="Attend the Event" />
        </div>

        {/* <div className="d-flex justify-content-center">
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" id="places-tab" data-toggle="pill" href="#places" role="tab" aria-controls="places" aria-selected="true">Places</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="people-tab" data-toggle="pill" href="#people" role="tab" aria-controls="people" aria-selected="false">People</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="processes-tab" data-toggle="pill" href="#processes" role="tab" aria-controls="processes" aria-selected="false">Processes</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="technology-tab" data-toggle="pill" href="#technology" role="tab" aria-controls="technology" aria-selected="false">Technology</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="special-tab" data-toggle="pill" href="#special" role="tab" aria-controls="special" aria-selected="false">Special</a>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="pills-tabContent" style={{ margin: `3rem 0 1rem 0`}}>
          <div className="tab-pane fade show active" id="places" role="tabpanel" aria-labelledby="places-tab">
            <Row>
              <div className="col-lg">
                <div className="mb-5">
                  <AwardCategoryShortlist title="Workplace &amp; Workspace Transformation" gridColor="#d23464" icon="computer-desk">
                    <Row>
                      <div className="col-lg">
                        <ShortlistOrganisation organisation="Defence Infrastructure Organisation" project="Smarter Working Programme" logo="dio" />
                      </div>
                    </Row>
                  </AwardCategoryShortlist>
                </div>
              </div>
              <div className="col-lg">
                <div className="mb-5">
                  <AwardCategoryShortlist title="Asset Management Innovation" gridColor="#d23464" icon="cog">
                    <Row>
                      <div className="col-lg">
                        <ShortlistOrganisation organisation="Tameside Metropolitan Borough Council" project="Tameside One" logo="tameside-mbc" />
                      </div>
                    </Row>
                  </AwardCategoryShortlist>
                </div>
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <div className="mb-5">
                  <AwardCategoryShortlist title="Efficiency in The Property Portfolio" gridColor="#d23464" icon="office">
                    <Row>
                      <div className="col-lg">
                        <ShortlistOrganisation organisation="NHS Property Services" project="120 The Broadway" logo="nhs-property" />
                      </div>
                    </Row>
                  </AwardCategoryShortlist>
                </div>
              </div>
              <div className="col-lg">
                <div className="mb-5">
                  <AwardCategoryShortlist title="Best Use of a Solution" gridColor="#d23464" icon="light-bulb">
                    <Row>
                      <div className="col-lg">
                        <ShortlistOrganisation organisation="The Queen Elizabeth Hospital King's Lynn NHS Foundation Trust" project="The Same Day Emergency Care Unit" logo="qehkl" />
                      </div>
                    </Row>
                  </AwardCategoryShortlist>
                </div>
              </div>
            </Row>
          </div>
          <div className="tab-pane fade" id="people" role="tabpanel" aria-labelledby="people-tab">
            <Row>
              <div className="col-lg">
                <div className="mb-5">
                  <AwardCategoryShortlist title="Corporate Leadership, Management &amp; Culture" gridColor="#d23464" icon="people">
                    <Row>
                      <div className="col-lg">
                        <ShortlistOrganisation organisation="HM Revenue &amp; Customs" project="Smarter Ways of Working 'Simply the way we do things here'" logo="hmrc" />
                      </div>
                    </Row>
                  </AwardCategoryShortlist>
                </div>
              </div>
              <div className="col-lg">
                <div className="mb-5">
                  <AwardCategoryShortlist title="Skills &amp; Succession Planning" gridColor="#d23464" icon="cog-person">
                    <Row>
                      <div className="col-lg">
                        <ShortlistOrganisation organisation="Department for Education" project="Building Skills for Smarter Working in DfE" logo="dfe" />
                      </div>
                    </Row>
                  </AwardCategoryShortlist>
                </div>
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <div className="mb-5">
                  <AwardCategoryShortlist title="Workforce Management" gridColor="#d23464" icon="people-arrow">
                    <Row>
                      <div className="col-lg">
                        <ShortlistOrganisation organisation="NHS London Procurement Partnership" project="The Workforce Alliance Permanent &amp; Temporary Staffing Solutions Suite" logo="nhslpp" />
                      </div>
                    </Row>
                  </AwardCategoryShortlist>
                </div>
              </div>
              <div className="col-lg">
                <div className="mb-5">
                  <AwardCategoryShortlist title="Best Use of a Solution" gridColor="#d23464" icon="light-bulb">
                    <Row>
                      <div className="col-lg">
                        <ShortlistOrganisation organisation="Leicestershire Partnership NHS Trust" project="Implementation of AutoPlanner within District Nursing" logo="lpnt" />
                      </div>
                    </Row>
                  </AwardCategoryShortlist>
                </div>
              </div>
            </Row>
          </div>
          <div className="tab-pane fade" id="processes" role="tabpanel" aria-labelledby="processes-tab">
            <Row>
              <div className="col-lg">
                <div className="mb-5">
                  <AwardCategoryShortlist title="Automation (Digital / Robotic / AI)" gridColor="#d23464" icon="brain">
                    <Row>
                      <div className="col-lg">
                        <ShortlistOrganisation organisation="West Midlands Police" project="Centre for Applied Automation" logo="wmp" />
                      </div>
                    </Row>
                  </AwardCategoryShortlist>
                </div>
              </div>
              <div className="col-lg">
                <div className="mb-5">
                  <AwardCategoryShortlist title="Data &amp; Interoperability" gridColor="#d23464" icon="atom">
                    <Row>
                      <div className="col-lg">
                        <ShortlistOrganisation organisation="Nottinghamshire County Council" project="Business Intelligence – Power BI Dashboards" logo="ncc" />
                      </div>
                    </Row>
                  </AwardCategoryShortlist>
                </div>
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <div className="mb-5">
                  <AwardCategoryShortlist title="Paperless" gridColor="#d23464" icon="paper-shred">
                    <Row>
                      <div className="col-lg">
                        <ShortlistOrganisation organisation="Driver &amp; Vehicle Standards Agency" project="The Driving Examiner Services App" logo="dvsa" />
                      </div>
                    </Row>
                  </AwardCategoryShortlist>
                </div>
              </div>
              <div className="col-lg">
                <div className="mb-5">
                  <AwardCategoryShortlist title="Best Use of a Solution" gridColor="#d23464" icon="light-bulb">
                    <Row>
                      <div className="col-lg">
                        <ShortlistOrganisation organisation="DWP Digital" project="Data Control Service" logo="dwpd" />
                      </div>
                    </Row>
                  </AwardCategoryShortlist>
                </div>
              </div>
            </Row>
          </div>
          <div className="tab-pane fade" id="technology" role="tabpanel" aria-labelledby="technology-tab">
            <Row>
              <div className="col-lg">
                <div className="mb-5">
                  <AwardCategoryShortlist title="Unified Communications" gridColor="#d23464" icon="circle-dots">
                    <Row>
                      <div className="col-lg">
                        <ShortlistOrganisation organisation="Barnsley Metropolitan Borough Council" project="#DigitalFirst" logo="bmbc" />
                      </div>
                    </Row>
                  </AwardCategoryShortlist>
                </div>
              </div>
              <div className="col-lg">
                <div className="mb-5">
                  <AwardCategoryShortlist title="Efficiency Savings" gridColor="#d23464" icon="pound-sign">
                    <Row>
                      <div className="col-lg">
                        <ShortlistOrganisation organisation="NHS Digital" project="Health &amp; Social Care Network: Better Connectivity for Health" logo="nhsdigital" />
                      </div>
                    </Row>
                  </AwardCategoryShortlist>
                </div>
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <div className="mb-5">
                  <AwardCategoryShortlist title="Small Scale Project" gridColor="#d23464" icon="inward-arrows">
                    <Row>
                      <div className="col-lg">
                        <ShortlistOrganisation organisation="NHS Business Services Authority" project="Am I Exempt?" logo="nhsbsa" />
                      </div>
                    </Row>
                  </AwardCategoryShortlist>
                </div>
              </div>
              <div className="col-lg">
                <div className="mb-5">
                  <AwardCategoryShortlist title="Best Use of a Solution" gridColor="#d23464" icon="light-bulb">
                    <Row>
                      <div className="col-lg">
                        <ShortlistOrganisation organisation="London Ambulance Service NHS Trust" project="Collaboration with GoodSAM" logo="las" />
                      </div>
                    </Row>
                  </AwardCategoryShortlist>
                </div>
              </div>
            </Row>
          </div>
          <div className="tab-pane fade" id="special" role="tabpanel" aria-labelledby="special-tab">
            <Row>
              <div className="col-lg">
                <div className="mb-5">
                  <AwardCategoryShortlist title="Special Recognition" subtitle="Runner Up" gridColor="#d23464" icon="trophy">
                    <Row>
                      <div className="col-lg">
                        <ShortlistOrganisation organisation="Nottinghamshire County Council" project="Business Intelligence – Power BI Dashboards" logo="ncc" />
                      </div>
                    </Row>
                  </AwardCategoryShortlist>
                </div>
              </div>
              <div className="col-lg">
                <div className="mb-5">
                  <AwardCategoryShortlist title="Special Recognition" subtitle="Winner" gridColor="#d23464" icon="trophy">
                    <Row>
                      <div className="col-lg">
                        <ShortlistOrganisation organisation="Education and Skills Funding Agency" project="AMSD Knowledge Management and Smarter Working" logo="esfa" />
                      </div>
                    </Row>
                  </AwardCategoryShortlist>
                </div>
              </div>
            </Row>
            <Row>
              <div className="col-lg">
                <div className="mb-5">
                  <AwardCategoryShortlist title="Smarter Working Project of the Year" subtitle="Runner Up" gridColor="#d23464" icon="combination">
                    <Row>
                      <div className="col-lg">
                        <ShortlistOrganisation organisation="HM Revenue &amp; Customs" project="Smarter Ways of Working 'Simply the way we do things here'" logo="hmrc" />
                      </div>
                    </Row>
                  </AwardCategoryShortlist>
                </div>
              </div>
              <div className="col-lg">
                <div className="mb-5">
                  <AwardCategoryShortlist title="Smarter Working Project of the Year" subtitle="Winner" gridColor="#d23464" icon="combination">
                    <Row>
                      <div className="col-lg">
                        <ShortlistOrganisation organisation="Department for Education" project="Building a Culture of Smarter Working in DfE" logo="dfe" />
                      </div>
                    </Row>
                  </AwardCategoryShortlist>
                </div>
              </div>
            </Row>
          </div>
        </div>
        <div className="text-center" style={{ marginBottom: `6rem`}}>
          <PrimaryButton to="/shortlist/" text="View the Shortlist" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <PrimaryButton to="/watch/" text="Watch the Ceremony" />
        </div> */}
      </Container>
    </Layout>
  </Background>
)

export default ShortlistPage
